import Vue from 'vue'
import App from './App.vue'
import router from './router'
import CustomerComponents from './components/index';//安装全局自定义组件
Vue.use(CustomerComponents);

Vue.config.productionTip = false
Vue.prototype.requestUrl = 'http://49.232.226.103:8080/zjx/api/';
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
