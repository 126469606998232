/**
  *1.首先需要引入组件
  *2.components中添加进去即可  这时候相当于已经全局注册了
  ****注意：组件必须定义name  以短横杆为命名规则 调用使用短横杆方式调用  例： name: 'comming-soon',    <comming-soon></comming-soon>
  使用方式： 在页面组件中不需要引入 不需要注册组件  直接使用即可         例： <comming-soon></comming-soon>
**/

import headerText from './header.vue'
import footerText from './footer.vue'
import footerEn from './footerEn.vue'
const components = [
	headerText,
	footerText,
	footerEn
];




const install = (Vue) => {
	if (install.installed) return;
	//注册全局组件
	components.map(component => {
		if (!component.name) console.error('必须指定组件name', component);
		Vue.component(component.name, component);
	})
	install.installed = true;
}

export default {
	version: '1.0.0',
	install
}