import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: '首页',
            component: resolve => require(['./views/index/index.vue'], resolve),
        },
        {
            path: '/indexEn',
            name: '首页',
            component: resolve => require(['./views/index/indexEn.vue'], resolve),
        },
        {
            path: '/classroom',
            name: '最美课本',
            component: resolve => require(['./views/classroom/classroom.vue'], resolve),
        },
        {
            path: '/classroomEn',
            name: '最美课本',
            component: resolve => require(['./views/classroom/classroomEn.vue'], resolve),
        },
        {
            path: '/education',
            name: '教育公益',
            component: resolve => require(['./views/education/education.vue'], resolve),
        },
        {
            path: '/educationEn',
            name: '教育公益',
            component: resolve => require(['./views/education/educationEn.vue'], resolve),
        },
        {
            path: '/win',
            name: '合作共赢',
            component: resolve => require(['./views/win/win.vue'], resolve),
        },
        {
            path: '/winEn',
            name: '合作共赢',
            component: resolve => require(['./views/win/winEn.vue'], resolve),
        },
        {
            path: '/companyNews',
            name: '公司新闻',
            component: resolve => require(['./views/companyNews/companyNews.vue'], resolve),
        },
        {
            path: '/companyNewsEn',
            name: '公司新闻',
            component: resolve => require(['./views/companyNews/companyNewsEn.vue'], resolve),
        },
        {
            path: '/newDetailsOne',
            name: '公司新闻详情',
            component: resolve => require(['./views/companyNews/newDetailsOne.vue'], resolve),
        },
        {
            path: '/newDetailsOneEn',
            name: '公司新闻详情',
            component: resolve => require(['./views/companyNews/newDetailsOneEn.vue'], resolve),
        },
        {
            path: '/about',
            name: '关于我们',
            component: resolve => require(['./views/about/about.vue'], resolve),
        },
        {
            path: '/aboutEn',
            name: '关于我们',
            component: resolve => require(['./views/about/aboutEn.vue'], resolve),
        },
    ]
});

router.beforeEach((to, from, next) => {
    if (to.path === '/') {
        if (/Android|iPhone|iPod/i.test(navigator.userAgent)) {
            next('/indexEn');
        } else {
            next();
        }
    }else if (to.path === '/indexEn'){
        if (!/Android|iPhone|iPod/i.test(navigator.userAgent)) {
            next('/');
        } else {
            next();
        }
    }else{
        next();
    }
    
})
router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
      router.replace(targetPath);
    }
});
export default router;
